<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header">
            <div class="row">
              <div class="col-md-8" data-v-step="1">
                <strong>Unidades e Conversores</strong>
              </div>
              <div class="col-md-4" data-v-step="4" style="text-align:right;">
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "listaconversores.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
        <b-tabs content-class="mt-3">
            <b-tab title="Conversores" active>
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th style="width:5%"></th>
                        <th style="width:20%">De</th>
                        <th style="width:20%">Para</th>
                        <th style="width:10%">Divisor</th>
                        <th style="width:45%"></th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(materialItem, index) in mun" :key="index">
                            <td data-title="Ativo?">
                            <b-form-checkbox
                                v-model="mun[index].mun_active" 
                                switch
                            ></b-form-checkbox>
                            </td>
                            <td data-title="De">
                            <b-form-select size="sm" class="verde" v-model.lazy="mun[index].mun_sigla" @change="editar(index)" :options="siglas"></b-form-select>
                            </td>
                            <td data-title="Para">
                            <b-form-select size="sm" class="verde" v-model.lazy="mun[index].mun_name" @change="editar(index)" :options="siglas"></b-form-select>
                            </td>
                            <td data-title="Divisor">
                            <vue-autonumeric class="form-control verde direita" @blur.native="editar(index)" :options="decimal" v-model="mun[index].mun_conv"></vue-autonumeric>
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                        <tr id="adicionando">
                            <td data-title="NOVO:"></td>
                            <td data-title="De">
                                <b-form-select size="sm" v-model="munSigla" :options="siglas"></b-form-select>
                            </td>
                            <td data-title="Para">
                                <b-form-select size="sm" v-model="munName" :options="siglas"></b-form-select>
                            </td>
                            <td data-title="Divisor"><vue-autonumeric class="form-control" v-model="munConv"></vue-autonumeric></td>
                            <td data-title="Criar novo"><button class="btn btn-success" @click="adicionar()">Adicionar</button></td>
                        </tr>
                    </tbody>
                </table>
            </b-tab>
            <b-tab title="Unidades de Compra">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th style="width:5%"></th>
                        <th style="width:45%">Unidade de compra</th>
                        <th style="width:50%"></th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(materialItem2, index) in mun2" :key="index">
                            <td data-title="Ativo?">
                            <b-form-checkbox
                                v-model="mun2[index].mun_active" 
                                switch
                            ></b-form-checkbox>
                            </td>
                            <td data-title="Un. de compra">
                            <b-form-input class="verde" v-model="mun2[index].mun_sigla" @blur.native="editar2(index)"></b-form-input>
                            </td>
                            <td>
                                
                            </td>
                        </tr>
                        <tr id="adicionando">
                            <td data-title="NOVA:"></td>
                            <td data-title="Un. de compra"><b-form-input v-model="munSigla"></b-form-input></td>
                            <td data-title="Criar nova"><button class="btn btn-success" @click="adicionar2()">Adicionar</button></td>
                        </tr>
                    </tbody>
                </table>
            </b-tab>
        </b-tabs>
        </b-card>
        </div>
        <b-modal id="modal-ajuda" title="Ajuda sobre Unidades e Conversores">
        <div class="row">
        <div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header">
            <strong>Como funciona?</strong>
            </div>
            <b-card-body>
              <p>Na aba <strong>UNIDADES DE COMPRA</strong>, você cria, ativa ou desativa as unidades de medida que serão utilizadas na aplicação.</p>
              <hr />
              <p>Na aba <strong>CONVERSORES</strong>, você utiliza as unidades de compra para a elaboração de conversores.</p>
              <p><u>Em DE:</u> escolha a unidade a ser convertida. Caso não exista, crie a mesma na aba UNIDADES DE COMPRA.</p>
              <p><u>Em PARA:</u> escolha a unidade para qual quer converter. Caso não exista, crie a mesma na aba UNIDADES DE COMPRA.</p>
              <p><u>Em DIVISOR:</u> Atenção aqui. Trata-se de colocar o número pelo qual a unidade a ser convertida será dividia para se chegar na unidade para qual se quer converter.<br>Veja no exemplo abaixo:</p>
              <ul>
                <li><em>Tenho um pactote de 500 gramas e quero converter para grama</em></li>
                <li>No campo DE, eu escolho "pacote de 500g" (caso não exista, eu crio na aba UNIDADES DE COMPRA).</li>
                <li>No campo PARA, eu escolho "grama".</li>
                <li>No campo DIVISOR, eu digito "500"</li>
                <li>OBS: O sistema salva automaticamente na medida que se preenche ou troca os campos</li>
              </ul>
            </b-card-body>
          </b-card>
        </div>
        </div>
        </b-modal>
        <b-modal size="lg" id="ajuda" hide-footer title="Unidades e Conversores">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/8cXlVdX9dT8?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div>
        <a v-if="isMobile()" href="#adicionando" class="btnfloat">
      <CIcon name="cil-plus" class="my-btnfloat" />
    </a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      materialItem: [],
      materialItem2: [],
      original: [],
      munSearch: '',
      sessao: '',
      loading: false,
      mun: [],
      mun2: [],
      munName: '',
      munSigla: '',
      munConv: 1,
      munActive: 1,
      siglas: [],
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      matExcel: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_UNIDADES'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.recarregar(true)
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function (ok) {
      if (ok) {
        this.listar('filtro_Conversao', 'mun_sigla', '').then(
          (response) => {
            this.mun = response
            for (var j = 0; j < response.length; j++) {
              this.matExcel.push({
                De: response[j].mun_sigla,
                Para: response[j].mun_name,
                Divisor: response[j].mun_conv,
                Ativo: response[j].mun_active
              })
            }
            this.listar('filtro_Unidade', 'mun_sigla', '').then(
                (response2) => {
                    this.mun2 = response2
                    this.siglas = []
                    if (response2.length === 0) {
                      this.$swal('Tabela de unidades vazia!', 'Aguarde enquanto sistema cria a estrutura de unidades e conversores padrão.', 'warning')
                      this.loading = false
                      // Cria os registros padrao
                      var w = '?filter=' + encodeURIComponent('grp_ID IS NULL')
                      var ordby = '&order=' + encodeURI('mun_ID')
                      this.$http.get(this.URLApi + 'prolucro/_table/pro_Material_Unidade' + w + ordby, {
                        headers: {
                          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                          'X-Dreamfactory-API-Key': this.ChaveApi,
                          'Content-Type': 'application/json'
                        }
                      }).then((response) => {
                        var r = response.body.resource
                        if (!r) { r = response.body }
                        var p = []
                        for (var i = 0; i < r.length; i++) {
                          p.push(this.inserir('pro_Material_Unidade',{resource: [ {mun_name: r[i].mun_name, mun_sigla: r[i].mun_sigla, mun_conv: r[i].mun_conv, mun_active: r[i].mun_active} ]}, '', '1'))
                        }
                        this.loading = true
                        Promise.all(p).then(
                          (k) => {
                            console.log(k)
                            this.$notify({
                              type: 'success',
                              title: 'Registros de Unidades e Conversores Criados',
                              text: 'Os registros padrões foram criados com sucesso.'
                            })
                            this.loading = false
                            this.recarregar(true)
                          },
                          (k) => {
                            console.log(k)
                            this.loading = false
                          }
                        )
                      }, (response) => {})
                    }
                    for (var i = 0; i < response2.length; i++) {
                        this.siglas.push({value: response2[i].mun_sigla, text: response2[i].mun_sigla, disabled: !response2[i].mun_active})
                    }
                    this.loading = false
                }, (response2) => {
                    this.loading = false
                }
            )
          }, (response) => {
              this.loading = false
          }
        )
      } else {
          this.loading = false
      }
      return false
    },
    adicionar2: function () {
      if (this.munSigla === '') {
        this.$swal('Preencha o nome da unidade de compra')
        return false
      }
      // this.munSigla = this.munSigla.replace(/[^A-Z0-9]+/ig, '').toUpperCase()
      if (this.munSigla.length > 15) {
        this.$swal('O tamanho máximo do campo é de 15 caracteres')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_ID', '(mun_sigla = ' + this.munSigla + ') AND (mun_name = ' + this.munSigla + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe este registro: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir (' + JSON.stringify(response) + '): O registro já existe.')
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material_Unidade', {resource: [ {mun_sigla: this.munSigla, mun_name: this.munSigla, mun_conv: 1, mun_active: 1} ]}).then(
            (response) => {
              this.munName = ''
              this.munSigla = ''
              this.munConv = ''
              this.recarregar(true)
            },
            (response) => {
              this.$swal('Erro ao inserir (' + JSON.stringify(response) + '): O registro já existe.')
              this.loading = false
            }
          )
        }
      )
    },
    adicionar: function () {
      if (this.munSigla === '' || this.munName === '' || this.munConv === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // this.munSigla = this.munSigla.replace(/[^A-Z0-9]+/ig, '').toUpperCase()
      if (this.munSigla.length > 15 || this.munName.length > 15) {
        this.$swal('O tamanho máximo do campo é de 15 caracteres')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_ID', '(mun_sigla = ' + this.munSigla + ') AND (mun_name = ' + this.munName + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe um registro deste tipo: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir (' + JSON.stringify(response) + '): O registro já existe.')
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material_Unidade', {resource: [ {mun_sigla: this.munSigla, mun_name: this.munName, mun_conv: this.munConv, mun_active: 1} ]}).then(
            (response) => {
              this.munName = ''
              this.munSigla = ''
              this.munConv = ''
              this.recarregar(true)
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      if (this.mun[index].mun_sigla === '' || this.mun[index].mun_name === '' || this.mun[index].mun_conv === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_sigla', '(mun_sigla = ' + this.mun[index].mun_sigla + ') AND (mun_name = ' + this.mun[index].mun_name + ') AND (mun_ID != ' + this.mun[index].mun_ID + ')').then(
        (response) => {
          /* if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe um registro deste tipo: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar registro existente: ' + JSON.stringify(response))
          } */
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Material_Unidade', {resource: [ {mun_sigla: this.mun[index].mun_sigla, mun_name: this.mun[index].mun_name, mun_conv: this.mun[index].mun_conv, mun_active: this.mun[index].mun_active} ]}, 'mun_ID = ' + this.mun[index].mun_ID).then(
            (response) => {
              this.recarregar(true)
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar2: function (index) {
      if (this.mun2[index].mun_sigla === '') {
        this.$swal('O nome da unidade de compra é obrigatória')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_sigla', '(mun_sigla = ' + this.mun2[index].mun_sigla + ') AND (mun_name = ' + this.mun2[index].mun_sigla + ') AND (mun_ID != ' + this.mun2[index].mun_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe esse registro: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          // Pega o nome anterior
          var anterior = ''
          this.campo('pro_Material_Unidade', 'mun_sigla', 'mun_ID = ' + this.mun2[index].mun_ID).then(
            (r) => {
              anterior = r
              this.loading = true
              this.atualizar('pro_Material_Unidade', {resource: [ {mun_sigla: this.mun2[index].mun_sigla, mun_name: this.mun2[index].mun_sigla, mun_conv: 1, mun_active: this.mun2[index].mun_active} ]}, 'mun_ID = ' + this.mun2[index].mun_ID).then(
                (response) => {
                  // Atualiza todos os conversores
                  this.atualizar('pro_Material_Unidade', {resource: [ {mun_sigla: this.mun2[index].mun_sigla} ]}, '(mun_sigla = ' + anterior + ') AND (grp_ID = ' + this.$session.get('grupo') + ')', '', '1')
                  this.atualizar('pro_Material_Unidade', {resource: [ {mun_name: this.mun2[index].mun_sigla} ]}, '(mun_name = ' + anterior + ') AND (grp_ID = ' +  this.$session.get('grupo') + ')', '', '1')
                  this.recarregar(true)
                },
                (response) => {
                  this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
                  this.loading = false
                }
              )
            },
            (r) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(r))
              return false
            }
          )
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>